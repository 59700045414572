<template>
  <div>
    <table class="uk-table uk-table-divider">
      <tbody>
        <tr>
          <td>Date Joined</td>
          <td>{{ membership.joined }}</td>
        </tr>
        <tr>
          <td>Membership Expiry</td>
          <td>{{ membership.expiry }}</td>
        </tr>
      </tbody>
    </table>
    
    <h3 class="mt-5">Extend your membership.</h3>
    <div class="uk-grid">
      <div
        class="uk-width-1-4@m uk-margin-small-bottom"
        v-for="(plan, i) in membership_plans"
        :key="`plan-${i}`"
      >
        <div class="uk-card uk-card-primary uk-card-body uk-card-hover">
          <div class="uk-text-center">
            <h1 class="uk-margin-small">{{ plan.months }}</h1>
            <h4 class="uk-margin-small">Month{{ plan.months == 1 ? "" : "s" }}</h4>
            <h2 class="uk-margin-small">
              $ {{ plan.whole }}.<small style="font-size: 16px">{{
                plan.cent
              }}</small>
            </h2>
            <router-link
              :to="{ name: 'trader.my-account.extend', params: { id: plan.id }}"
              class="uk-button uk-button-primary"
              >Pay Now</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      membership_plans: [],
      membership: {},
    };
  },

  methods: {
    fetch() {
      this.$axios
        .get("/api/v1/trader/my-account/membership")
        .then((response) => {
          this.membership_plans = response.data.membership_plans;
          this.membership = response.data.membership;
        });
    },
  },
};
</script>
